import { useTranslation } from 'react-i18next';
import { createRef, useState } from "react";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

// COMPONETS
import Heading from "../../Components/Heading/Heading";
import Landing from "../../Components/Landing/Landing";
import OfferCard from "../../Components/OfferCard/OfferCard";
import SlidingMenu from "../../Components/SlidingMenu/SlidingMenu";
import WydajnoscFrame from "../inHomeFrames/WydajnoscFrame";
import Footer from "../../Components/Footer/Footer";
import SkalowalnoscFrame from "../inHomeFrames/SkalowalnoscFrame";
import OgraniczeniaFrame from "../inHomeFrames/OgraniczeniaFrame";

// STYLES
import "./Home.sass";

// IMAGES
import wydajnosc from "../../assets/icons/wydajnosc.svg";
import obciazenie from "../../assets/icons/obciazenie.svg";
import peak from "../../assets/icons/peak.svg";
import overload from "../../assets/icons/overload.svg";
import longtime from "../../assets/icons/longtime.svg";
import sql from "../../assets/icons/sql.svg";
import users from "../../assets/icons/users.svg";
import responsive from "../../assets/icons/responsive.svg";
import devices from "../../assets/icons/devices.svg";
import accessibility from "../../assets/icons/accessibility.svg";
import form from "../../assets/icons/form.svg";
import code from "../../assets/icons/code.svg";
import php from "../../assets/icons/php.svg";
import zaufali from "../../assets/images/zaufali_nam.webp";

const Home = () => {
  const { t } = useTranslation();
  const recaptchaRef = createRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState(t("interested_contact_me"));
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const handleInputPhone = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");

    setPhone(inputValue);
  };

  const onChangeCaptcha = (value) => {
    if (value !== null) {
      sendForm(value);
    } else {
      Swal.fire({
        text: t("recaptcha_validation_error"),
        icon: "error",
        toast: true,
        timer: 2500,
        position: "top",
        showConfirmButton: false,
      });
    }
  };

  const executeCaptcha = () => {
    recaptchaRef.current.execute();
  };

  const sendForm = (recaptchaValue) => {
    let err = false;

    if (/\S+@\S+\.\S+/.test(email) === false) {
      document.querySelector("label[for=email]").style.color = "red";
      err = true;
    } else {
      document.querySelector("label[for=email]").style.color = "";
    }

    if (phone !== "") {
      if (phone.length < 8) {
        document.querySelector("label[for=phone]").style.color = "red";
        err = true;
      } else {
        document.querySelector("label[for=phone]").style.color = "";
      }
    } else {
      document.querySelector("label[for=phone]").style.color = "";
    }

    if (!err) {
      fetch("https://360totalsecurity.pl/sendmail/index.php", {
        method: "POST",
        header: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          msg: msg,
          recaptcha_value: recaptchaValue,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            Swal.fire({
              text: res.msg,
              icon: "success",
              toast: true,
              timer: 2500,
              position: "top",
              showConfirmButton: false,
            });

            setName("");
            setEmail("");
            setPhone("");
            setMsg(t("interested_contact_me"));
          } else {
            Swal.fire({
              text: res.msg,
              icon: "error",
              toast: true,
              timer: 2500,
              position: "top",
              showConfirmButton: false,
            });
          }
        });
    } else {
      Swal.fire({
        text: t("form_invalid_error"),
        icon: "error",
        toast: true,
        timer: 2500,
        position: "top",
        showConfirmButton: false,
      });
    }
  };

  const effects = [
    {
      name: t("effect_1"),
      href: "ocena",
      component: <WydajnoscFrame />,
    },
    {
      name: t("effect_2"),
      href: "problemy",
      component: <SkalowalnoscFrame />,
    },
    {
      name: t("effect_3"),
      href: "wskazanie-ograniczen",
      component: <OgraniczeniaFrame />,
    },
  ];

  return (
    <div className="home">
      <Landing />
      <div className="content">
        <section className="section--2col" id={t("about2")}>
          <div>
            <Heading start="left">
              <h2>{t('about')}</h2>
              <p>
                {t("7_years")}
              </p>
            </Heading>
            <br />
            <p className="margin-left-30">
              <b>
                {t("we_have")}
                {t("such_us")}
              </b>
            </p>
            <ul className="olist">
              <li>{t("cyberSecurity")}</li>
              <li>{t("IT")}</li>
              <li>{t("Banking")}</li>
              <li>{t("Accounting")}</li>
              <li>{t("GrantsAndSubsidies")}</li>
              <li>
                {t("creating_and_deploying")}
              </li>
              <li>{t("GDPR")}</li>
            </ul>
          </div>
          <div className="img-placeholder"></div>
        </section>
        <section id={t("audit2")}>
          <Heading start="right">
            <h2>{t("order_audit_header")}</h2>
            <p>
            {t("order_audit_description")}
            </p>
          </Heading>
          <div className="offer-grid">
            <OfferCard
              icon={wydajnosc}
              title={t("performance_test_title")}
              description={t("performance_test_description")}
            />
            <OfferCard
              icon={obciazenie}
              title={t("load_test_title")}
              description={t("load_test_description")}
            />
            <OfferCard
              icon={peak}
              title={t("peak_load_test_title")}
              description={t("peak_load_test_description")}
            />
            <OfferCard
              icon={overload}
              title={t("overload_test_title")}
              description={t("overload_test_description")}
            />
            <OfferCard
              icon={longtime}
              title={t("longtime_stability_test_title")}
              description={t("longtime_stability_test_description")}
            />
            <OfferCard
              icon={sql}
              title={t("sql_injection_test_title")}
              description={t("sql_injection_test_description")}
            />
            <OfferCard
              icon={users}
              title={t("user_permissions_test_title")}
              description={t("user_permissions_test_description")}
            />
            <OfferCard
              icon={responsive}
              title={t("responsiveness_test_title")}
              description={t("responsiveness_test_description")}
            />
            <OfferCard
              icon={devices}
              title={t("cross_browser_test_title")}
              description={t("cross_browser_test_description")}
            />
            <OfferCard
              icon={accessibility}
              title={t("ui_ux_accessibility_test_title")}
              description={t("ui_ux_accessibility_test_description")}
            />
            <OfferCard
              icon={form}
              title={t("form_validation_test_title")}
              description={t("form_validation_test_description")}
            />
            <OfferCard
              icon={code}
              title={t("code_review_test_title")}
              description={t("code_review_test_description")}
            />
            <OfferCard
              icon={php}
              title={t("php_injection_test_title")}
              description={t("php_injection_test_description")}
            />
          </div>
        </section>
        <section id={t("efects2")}>
          <Heading start="left">
            <h2>{t("test_results_header")}</h2>
            <p>
            {t("test_results_description")}
            </p>
          </Heading>
          <SlidingMenu content={effects} />
        </section>
        <section id="audyt-bezpieczenstwa">
          <Heading start="right">
            <h2>{t("final_provisions")}</h2>
            <p>
              {t("customize_all_services")}
            </p>
          </Heading>
          <p>
            <h2>
              {t("offer_shows_general_capabilities")}
            </h2>
            <ul className="olist">
              <li>
                <u>{t("information")}</u>, {t("most_needed_tests_services")}
              </li>
              <li>
                <u>{t("app_information")}</u>, {t("goal_of_our_tests")}
              </li>
              <li>
                <u>{t("website_information")}</u>, {t("related_to_application")}
              </li>
              <li>
                {t("according_to_art")}{" "}
                <u>{t("discuss_the_entire_process")}</u> ({t("physically_or_online")}),
                {t("current_issues_with_the_application_etc")}
              </li>
            </ul>
          </p>
        </section>
        <section id={t("trust_us2")}>
          <Heading start="left">
            <h2>{t("why_us")}</h2>
            <p>
             {t("many_people_and_companies_have_trusted_us")}
            </p>
          </Heading>
          <img
            className="section__img"
            src={zaufali}
            alt="Firmy które nam zaufały"
          />
        </section>
        <section id={t("contact2")}>
          <Heading start="right">
            <h2>{t("contact_us")}</h2>
            <p>{t("use_the_contact_form_below")}</p>
          </Heading>
          <div className="contact">
            <div>
              <label htmlFor="name">{t("name")}</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="email">{t("email_address")}</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="phone">{t("phone_number")}</label>
              <input type="text" value={phone} onChange={handleInputPhone} />
            </div>
            <div>
              <label htmlFor="msg">{t("message_content")}</label>
              <textarea
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
              ></textarea>
            </div>
            <ReCAPTCHA
              style={{ zIndex: "999" }}
              sitekey="6LcKPm4qAAAAALWrGV-DPvdnUiWFZkYcG5tp_oyv"
              size="invisible"
              ref={recaptchaRef}
              onChange={onChangeCaptcha}
            />
            <button onClick={executeCaptcha}>{t("send_button")}</button>
          </div>
        </section>
      </div>
      <Footer />

      <a className="mobileContact" href="#kontakt">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-envelope-fill"
          viewBox="0 0 16 16"
        >
          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
        </svg>
      </a>
    </div>
  );
};

export default Home;
